import styled, { createGlobalStyle } from "styled-components";
import { Link as LinkRouter } from "react-router-dom";
// images
import img from "../../images/background.jpg";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
`;

export const TableContainer = styled.div`
  width: 40%;
  color: white;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const Table = styled.table`
  background-color: rgba(225, 117, 27, 0.7);
  width: 100%;
  border-bottom: solid 2px white;
  padding: 20px 20px 20px 0px;
  & td {
    font-size: 20px;
    padding-right: 10px;
  }
`;

export const PositionTd = styled.td`
  text-align: center;
`;

export const NameTd = styled.td`
  text-align: left;
`;

export const ScoreTd = styled.td`
  text-align: right;
`;

export const TableBottomContainer = styled.div`
  background-color: rgba(225, 117, 27, 0.7);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  & img {
    max-width: 50%;
    margin: 20px;
  }
`;

export const ExitButton = styled(LinkRouter)`
  padding: 5px 15px 5px 15px;
  background-color: rgba(225, 117, 27, 0.7);
  color: white;
  border-color: white;
  font-size: 20px;
  cursor: pointer;
  border: solid 1px;
  border-radius: 50px;
  margin-top: 20px;
  text-decoration: none;
  &:hover {
    background-color: #3a4b66;
  }
`;

export const GlobalStyle = createGlobalStyle`
    body{
        background: url(${img}) repeat 0 0;
        background-size: cover;
    }
  
`;
