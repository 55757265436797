import React, { Fragment, useState } from "react";
// styles
import {
  Container,
  Title,
  Attemps,
  Phrase,
  Image,
  ButtonsContainer,
  PlayButton,
  CantPlayButton,
  ExcelButton,
  LogoutButton,
  GlobalStyle,
  Wom,
  Man,
} from "./styles";
// state
import { useStateValue } from "../../state/Context";
// images
import logo from "../../images/logos/Recurso-10.png";
// import wom from "../../images/wom.png";
// import man from "../../images/man.png";
// rest
import { DownloadExcelFile } from "../../services/RestService";

export const MainPage = () => {
  const [{ userState }, dispatch] = useStateValue();
  const [isAdmin, setIsAdmin] = useState(userState.isAdmin);
  const [attemps, setAttemps] = useState(userState.attemps);

  const logOut = () => {
    dispatch({ type: "LOGOUT" });
    localStorage.clear();
  };

  const handleExcelClick = async () => {
    try {
      await DownloadExcelFile();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <GlobalStyle />
      <Container>
        <Title>
          Bienvenido{" "}
          {userState.name.charAt(0).toUpperCase() + userState.name.slice(1)}
        </Title>
        <Attemps>{`Intentos: ${userState.attemps}/2`}</Attemps>
        <Phrase>
          Prepárate para hacer todos los puntos posibles y competir por los
          primeros lugares.
        </Phrase>
        <ButtonsContainer>
          {attemps < 1 ? (
            <CantPlayButton to="/main">0 Intentos</CantPlayButton>
          ) : (
            <PlayButton to="/game">Empezar</PlayButton>
          )}
          <PlayButton to="/ranking">Ranking</PlayButton>
        </ButtonsContainer>
        {isAdmin === 1 && (
          <ExcelButton onClick={handleExcelClick}>
            Descargar Excel de usuarios
          </ExcelButton>
        )}
        <LogoutButton onClick={logOut}>Cerrar Sesión</LogoutButton>
        <div>
          <Image src={logo} />
        </div>
        {/* <Wom>
          <img src={wom} />
        </Wom>
        <Man>
          <img src={man} />
        </Man> */}
      </Container>
    </Fragment>
  );
};
