import { createGlobalStyle } from "styled-components";
import font from "../fonts/MyriadPro-Regular.otf";

export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: "MyriadPro";
        src: url(${font});
    }

    html, body{ 
        margin: 0;
    }

    html{
        font-family: MyriadPro;
    }
`;
