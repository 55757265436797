import React, { Fragment } from "react";
// styles
import { Container, ExitButton, GlobalStyle } from "./styles";
// components
import { RankingTable } from "../../components/RankingTable";

export const RankingPage = () => {
  return (
    <Fragment>
      <GlobalStyle />
      <Container>
        <h1>RANKING</h1>
        <RankingTable />
        <ExitButton to="/main">Salir al menú</ExitButton>
      </Container>
    </Fragment>
  );
};
