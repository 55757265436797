import React, { Fragment, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
// styles
import {
  Container,
  Card,
  Info,
  InfoTitles,
  Counter,
  Question,
  AnswersContainer,
  BackButton,
  GlobalStyle,
  Img,
} from "./styles";
// content
import { Questions } from "../../Content";
// npm
import { ToastContainer, toast } from "react-toastify";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
// state
import { useStateValue } from "../../state/Context";
// rest
import { updateScore, updateAttemps } from "../../services/RestService";
//components
import { RankingTable } from "../../components/RankingTable";
// images
import logosPositiva from "../../images/logos/Recurso-2.png";

export const GamePage = () => {
  let history = useHistory();
  const [{ userState }, dispatch] = useStateValue();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);

  const score = useRef(0);

  const handleNextQuestion = async () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < Questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      if (score.current > userState.score) {
        try {
          const scoreData = {
            score: score.current,
          };
          dispatch({
            type: "UPDATE_SCORE",
            newScore: {
              ...userState,
              score: scoreData.score,
            },
          });
          localStorage.setItem("userScore", JSON.stringify(scoreData.score));
          await updateScore(scoreData);
          swal(
            "Bien!",
            "Has superado tu record, tu puntaje se ha actualizado!",
            "success"
          );
        } catch (error) {
          console.log(error);
        }
      }
      setShowScore(true);
    }
  };

  const handleAnswerClick = (isCorrect) => {
    if (isCorrect) {
      score.current = score.current + 10;
      toast.success("Respuesta correcta!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    } else {
      toast.error("Respuesta incorrecta!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    handleNextQuestion();
  };

  const handleCloseGame = async () => {
    const attempsData = {
      attemps: userState.attemps - 1,
    };
    dispatch({
      type: "UPDATE_ATTEMPS",
      newAttemp: {
        ...userState,
        attemps: attempsData.attemps,
      },
    });
    localStorage.setItem("userAttemps", JSON.stringify(attempsData.attemps));
    await updateAttemps(attempsData);
    history.push("/main");
  };

  return (
    <Fragment>
      <GlobalStyle category={Questions[currentQuestion].category} />
      <Container>
        <Info>
          <InfoTitles>{userState.name}</InfoTitles>
          <h1>{`${currentQuestion + 1}/${Questions.length}`}</h1>
          <InfoTitles>{score.current} Puntos</InfoTitles>
        </Info>
        <Card>
          {showScore ? (
            <>
              <h1> Tu puntaje fue de {score.current} puntos!</h1>
              <p>
                Puedes ver tu posición del ranking en la siguiente tabla,
                recuerda que te quedan{" "}
                <strong>{userState.attemps - 1} intentos</strong>
              </p>
              <RankingTable />
              <BackButton onClick={() => handleCloseGame()}>
                Volver al menú
              </BackButton>
            </>
          ) : (
            <>
              <Counter>
                {" "}
                <CountdownCircleTimer
                  key={currentQuestion}
                  onComplete={() => {
                    handleAnswerClick(false);
                    return [true, 0];
                  }}
                  isPlaying
                  duration={25}
                  size={70}
                  strokeWidth={5}
                  colors={[
                    ["#004777", 0.33],
                    ["#F7B801", 0.33],
                    ["#A30000", 0.33],
                  ]}
                >
                  {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
              </Counter>
              <Question>{Questions[currentQuestion].questionText}</Question>
              <AnswersContainer>
                {Questions[currentQuestion].answerOptions.map((answer, id) => {
                  return (
                    <button
                      onClick={() => handleAnswerClick(answer.isCorrect)}
                      key={id}
                    >
                      {answer.answerText}
                    </button>
                  );
                })}
              </AnswersContainer>
            </>
          )}
        </Card>
        <Img>
          <img src={logosPositiva} />
        </Img>
      </Container>
      <ToastContainer autoClose={2000} />
    </Fragment>
  );
};
