import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  & img {
    padding: 20px;
    width: 25%;
  }
  @media (max-width: 600px) {
    & img {
      padding: 20px;
      width: 50%;
    }
  }
`;
