import styled from "styled-components";
import img from "../../images/login-background.jpg";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  min-height: 100vh;
  @media (max-width: 600px) {
    grid-template-columns: 100%;
    background-image: url(${img});
    background-size: cover;
  }
`;

export const LeftImage = styled.div`
  background: url(${img}) repeat 0 0;
  background-size: cover;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items:center;
  & p {
    padding: 40px 6vw;
    color: white;
    font-weight: lighter;
    width: 100%;
    background-color: #DF751B;
    font-size: 35px;
    text-align: left;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

export const RightForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const Logos = styled.div`
  display: flex;
  justify-content: center;
  & img {
    padding: 100px 20px 20px 20px;
    width: auto;
    max-width:300px
  }
  @media (max-width: 600px) {
    & img {
      padding: 30px 20px 30px 20px;
      max-width: 300px;
    }
  }
`;

export const LogosBottom = styled.div`
  display: flex;
  justify-content: center;
  & img {
    padding: 20px 20px 50px 20px;
    width: 300px;
  }
  @media (max-width: 600px) {
    & img {
      padding: 20px 20px 50px 20px;
      width: 200px;
    }
  }
`;

export const PositivaLogo = styled.img`
  width: 25%;
  padding-right: 20px;
`;

export const AmericanLogo = styled.img`
  width: 40%;
  margin-left: 20px;
`;
