export const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userState: action.newUserState,
      };

    case "UPDATE_SCORE":
      return {
        ...state,
        userState: action.newScore
      };

    case "UPDATE_ATTEMPS":
      return {
        ...state,
        userState: action.newAttemp
      };

    case "LOGOUT":
      return {
        ...state,
        userState: { isLogged: false },
      };

    default:
      return state;
  }
};
