export const Questions = [
  {
    category: "One",
    questionText:
      "La organización mundial para la salud OMS, define salud como:",
    answerOptions: [
      { answerText: "Estado de bienestar físico y mental", isCorrect: false },
      {
        answerText: "Estado de bienestar físico, mental y social completo",
        isCorrect: true,
      },
      { answerText: "Ausencia de enfermedades", isCorrect: false },
      { answerText: "Ausencia de dolor o síntomas", isCorrect: false },
    ],
  },
  {
    category: "One",
    questionText: "El trabajo es una actividad que implica un riesgo:",
    answerOptions: [
      { answerText: "Inevitable", isCorrect: false },
      { answerText: "Voluntario", isCorrect: false },
      {
        answerText: "Si las condiciones son defectuosas",
        isCorrect: true,
      },
      { answerText: "Pago", isCorrect: false },
    ],
  },
  {
    category: "One",
    questionText:
      "La posibilidad de que un trabajador sufra un daño derivado de su trabajo se denomina:",
    answerOptions: [
      { answerText: "Riesgo laboral", isCorrect: true },
      { answerText: "Accidente de trabajo", isCorrect: false },
      { answerText: "Daño con causa del trabajo", isCorrect: false },
      { answerText: "Daño colateral", isCorrect: false },
    ],
  },
  {
    category: "One",
    questionText:
      "Los riesgos derivados de CONDICIONES AMBIENTALES causan en la salud:",
    answerOptions: [
      { answerText: "Accidentes de trabajo", isCorrect: false },
      { answerText: "Enfermedades laborales", isCorrect: true },
      { answerText: "Inconformidad laboral", isCorrect: false },
      { answerText: "Calentamiento global", isCorrect: false },
    ],
  },
  {
    category: "One",
    questionText:
      "El uso de máquinas y herramientas están asociados a los riesgos:",
    answerOptions: [
      { answerText: "Químicos", isCorrect: false },
      { answerText: "Físicos", isCorrect: false },
      { answerText: "Mecánicos ", isCorrect: true },
      { answerText: "Locativos", isCorrect: false },
    ],
  },
  {
    category: "One",
    questionText: "Los riesgos laborales se pueden evitar:",
    answerOptions: [
      { answerText: "Nunca", isCorrect: false },
      { answerText: "Capacitando a los trabajadores", isCorrect: false },
      {
        answerText:
          "Mejorando las condiciones de trabajo",
        isCorrect: true,
      },
      { answerText: "Usando elementos de protección", isCorrect: false },
    ],
  },
  {
    category: "One",
    questionText:
      "¿Cuándo y dónde se aplican las actividades de prevención en el trabajo?",
    answerOptions: [
      {
        answerText: "En todas las actividades",
        isCorrect: true,
      },
      { answerText: "En las actividades de mayor riesgo", isCorrect: false },
      { answerText: "Cuando la ley lo exija  ", isCorrect: false },
      { answerText: "En las auditorías", isCorrect: false },
    ],
  },
  {
    category: "One",
    questionText:
      "La iluminación deficiente en un puesto de trabajo puede causar:",
    answerOptions: [
      { answerText: "Fatiga visual", isCorrect: true },
      { answerText: "Miopía", isCorrect: false },
      { answerText: "Cataratas", isCorrect: false },
      { answerText: "Hipoacusia", isCorrect: false },
    ],
  },
  {
    category: "One",
    questionText:
      "Los robos, atracos y asaltos hacen parte del grupo relacionado con:",
    answerOptions: [
      { answerText: "Riesgo público", isCorrect: true },
      { answerText: "Riesgo Mecánico", isCorrect: false },
      { answerText: "Seguridad industrial", isCorrect: false },
      { answerText: "Mala suerte", isCorrect: false },
    ],
  },
  {
    category: "One",
    questionText:
      "Una tarea que exige más concentración de lo habitual puede causar:",
    answerOptions: [
      { answerText: "Fatiga física", isCorrect: false },
      { answerText: "Fatiga muscular", isCorrect: false },
      { answerText: "Fatiga mental", isCorrect: true },
      { answerText: "Alzheimer", isCorrect: false },
    ],
  },
  {
    category: "Two",
    questionText:
      "Si los accidentes causan daños a la propiedad y/o lesiones a las personas, ¿qué podríamos definir como INCIDENTE?:",
    answerOptions: [
      {
        answerText:
          "Un evento con potencial de convertirse en accidente, sin daños o lesiones",
        isCorrect: true,
      },
      { answerText: "Es un accidente, pero leve ", isCorrect: false },
      { answerText: "Es una lesión de menor importancia", isCorrect: false },
      { answerText: "Ninguna de las anteriores", isCorrect: false },
    ],
  },
  {
    category: "Two",
    questionText:
      "¿Cómo se denomina al accidente con lesión, que se produce en el trayecto habitual entre el domicilio y el lugar de trabajo?",
    answerOptions: [
      { answerText: "Accidente involuntario", isCorrect: false },
      { answerText: "Accidente in itinere", isCorrect: false },
      { answerText: "Accidente de transito", isCorrect: true },
      { answerText: "Accidente casual", isCorrect: false },
    ],
  },
  {
    category: "Two",
    questionText:
      "Las acciones encaminadas a buscar el confort en el puesto de trabajo, como altura de pantalla, teclado, reposapiés entre otros, se enmarcan dentro de:",
    answerOptions: [
      { answerText: "Seguridad industrial", isCorrect: false },
      { answerText: "Ergonomía", isCorrect: true },
      { answerText: "Plan de emergencias", isCorrect: false },
      { answerText: "Mecatrónica", isCorrect: false },
    ],
  },
  {
    category: "Two",
    questionText: "Lo más importante en una emergencia es:",
    answerOptions: [
      { answerText: "Botiquín y extintor", isCorrect: false },
      { answerText: "Salir corriendo", isCorrect: false },
      { answerText: "Conservar la calma", isCorrect: true },
      { answerText: "El celular", isCorrect: false },
    ],
  },
  {
    category: "Two",
    questionText: "¿Cuál es la finalidad de las inspecciones?",
    answerOptions: [
      {
        answerText: "Identificar potenciales situaciones de riesgo",
        isCorrect: false,
      },
      {
        answerText: "Señalizar las situaciones que puedan ocasionar accidentes",
        isCorrect: false,
      },
      {
        answerText:
          "Adoptar las medidas preventivas oportunas para el control de los riesgos",
        isCorrect: true,
      },
      { answerText: "Quitar tiempo del trabajo", isCorrect: false },
    ],
  },
  {
    category: "Two",
    questionText: "El COPASST es: ",
    answerOptions: [
      {
        answerText:
          "Un mecanismo de intermediación en temas de seguridad y salud en el trabajo entre el empleador y los empleados ",
        isCorrect: true,
      },
      {
        answerText:
          "Un grupo de trabajadores que revisan los casos de acoso laboral",
        isCorrect: false,
      },
      { answerText: "Un mecanismo de reuniones mensuales", isCorrect: false },
      {
        answerText: "Supervisores del ministerio de trabajo",
        isCorrect: false,
      },
    ],
  },
  {
    category: "Two",
    questionText: "¿Qué es un peligro?",
    answerOptions: [
      {
        answerText: "A lo que me expongo por naturaleza de mi labor",
        isCorrect: false,
      },
      {
        answerText:
          "Fuente, situación o acto con potencial de causar daño en la salud de los trabajadores, equipos o instalaciones",
        isCorrect: true,
      },
      {
        answerText:
          "Es una combinación de probabilidades de que ocurra un evento peligroso y la severidad del daño que pueda causar ",
        isCorrect: false,
      },
      { answerText: "Adrenalina pura", isCorrect: false },
    ],
  },
  {
    category: "Two",
    questionText: "¿Qué es Riesgo?",
    answerOptions: [
      {
        answerText:
          "Es una combinación de probabilidades de que ocurra un evento peligroso y la severidad del daño que pueda causar",
        isCorrect: true,
      },
      {
        answerText:
          "Fuente, situación o acto con potencial de causar problemas psicológicos",
        isCorrect: false,
      },
      { answerText: "La exposición al peligro", isCorrect: false },
      { answerText: "Ninguna de las anteriores", isCorrect: false },
    ],
  },
  {
    category: "Two",
    questionText:
      "Para proteger la capacidad auditiva debemos evitar el uso indiscriminado de:",
    answerOptions: [
      { answerText: "Audífonos", isCorrect: true },
      { answerText: "Gorros", isCorrect: false },
      { answerText: "Tapabocas", isCorrect: false },
      { answerText: "Aretes", isCorrect: false },
    ],
  },
  {
    category: "Two",
    questionText: "Para evitar el contagio del COVID-19 se debe:",
    answerOptions: [
      {
        answerText: "Lavar las manos y conservar el distanciamiento social ",
        isCorrect: false,
      },
      {
        answerText:
          "Auto aislarse al tener síntomas respiratorios y usar tapabocas",
        isCorrect: false,
      },
      { answerText: "Evitar aglomeraciones", isCorrect: false },
      { answerText: "Todas las anteriores", isCorrect: true },
    ],
  },
];
