import { BACKEND_URL } from "../constants";
const axios = require("axios");

export const RegisterUser = async (data) => {
  const url = BACKEND_URL + "auth/signup";
  console.log("HTTP post to ", url, "whith payload ", data);
  return await axios.post(url, data);
};

export const LoginUser = async (data) => {
  const url = BACKEND_URL + "auth/login";
  console.log("HTTP post to ", url, "whith payload ", data);
  return await axios.post(url, data);
};

export const getRanking = async () => {
  const session = JSON.parse(localStorage.getItem("userData"));
  const url = BACKEND_URL + "score/get";
  console.log("HTTP get to ", url);
  return await axios.get(url, {
    headers: {
      Authorization: "Bearer " + session.token,
      Accept: "application/json",
    },
  });
};

export const updateScore = async (data) => {
  const session = JSON.parse(localStorage.getItem("userData"));
  const url = BACKEND_URL + "score/update";
  console.log("HTTP put to ", url);
  return await axios.put(url, data, {
    headers: {
      Authorization: "Bearer " + session.token,
      Accept: "application/json",
    },
  });
};

export const updateAttemps = async (data) => {
  const session = JSON.parse(localStorage.getItem("userData"));
  const url = BACKEND_URL + "attemps/update";
  console.log("HTTP put to ", url);
  return await axios.put(url, data, {
    headers: {
      Authorization: "Bearer " + session.token,
      Accept: "application/json",
    },
  });
};

export const DownloadExcelFile = async () => {
  const session = JSON.parse(localStorage.getItem("userData"));
  const url = BACKEND_URL + "excel/export";
  console.log("HTTP get to ", url);
  axios
    .request({
      url,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: "Bearer " + session.token,
        Accept: "application/json",
      },
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "usuarios-trivia-positiva.xlsx");
      link.click();
      link.remove();
    });
};
