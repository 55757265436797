import styled, { createGlobalStyle } from "styled-components";
import { Link as LinkRouter } from "react-router-dom";
// images
import img from "../../images/new-bg.jpg";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
`;

export const Title = styled.h1`
  font-size: 60px;
  margin: 0;
  margin-top: 5%;
  text-align: center;
  background-color: rgb(255 255 255 / 80%);
  color: #DF751B;
  padding: 25px 20px 20px;
  @media (max-width: 600px) {
    margin-top: 30%;
    font-size: 40px;
  }
`;

export const Attemps = styled.h1`
  color: white;
`;

export const Phrase = styled.p`
  font-size: 40px;
  text-align: center;
  padding: 5%;
  margin: 0;
  background-color: rgb(255 255 255 / 80%);
  color: #DF751B;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

export const Image = styled.img`
  width: 500px;
  margin-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 250px;
`;

export const PlayButton = styled(LinkRouter)`
  padding: 5px 15px 5px 15px;
  background-color: white;
  color: #ed7203;
  border-color: white;
  font-size: 20px;
  cursor: pointer;
  border: solid 1px;
  border-radius: 50px;
  margin-top: 20px;
  text-decoration: none;
  &:hover {
    background-color: #ed7203;
    color: white;
  }
`;

export const CantPlayButton = styled(LinkRouter)`
  padding: 5px 15px 5px 15px;
  background-color: gray;
  color: white;
  border-color: white;
  font-size: 20px;
  cursor: pointer;
  border: solid 1px;
  border-radius: 50px;
  margin-top: 20px;
  text-decoration: none;
  &:hover {
    background-color: #3a4b66;
  }
`;

export const ExcelButton = styled.button`
  padding: 5px 15px 5px 15px;
  background-color: #44dd70;
  color: white;
  border-color: white;
  font-size: 15px;
  cursor: pointer;
  border: solid 1px;
  border-radius: 50px;
  margin-top: 20px;
  text-decoration: none;
  &:hover {
    background-color: #ed7203;
  }
`;

export const LogoutButton = styled.button`
  color: white;
  font-size: 15px;
  cursor: pointer;
  border: none;
  margin-top: 20px;
  text-decoration: none;
  background-color: transparent;
  border-bottom: solid 1px white;
  padding: 5px;
  margin-bottom: 20px;
  &:hover {
    background-color: white;
    color: #ed7203;
  }
`;

export const Wom = styled.div`
  position: absolute;
  left: -100px;
  top: -200px;
  z-index: -1;
  & img {
    width: 800px;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

export const Man = styled.div`
  position: absolute;
  right: -100px;
  top: -200px;
  z-index: -1;
  & img {
    width: 800px;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

export const GlobalStyle = createGlobalStyle`
    body{
        background: url(${img}) repeat 0 0;
        background-size: cover;
        position: relative;
    }
`;
