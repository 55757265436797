import styled, { createGlobalStyle } from "styled-components";
// background images
import one from "../../images/bg-one.png";
import two from "../../images/bg-two.png";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;

`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  align-items: center;
  margin-bottom: 50px;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const InfoTitles = styled.h1`
  border: 2px solid;
  border-color: white;
  border-radius: 10px;
  font-size: 20px;
  padding: 10px;
`;

export const Title = styled.h1`
  margin-top: 0;
  font-size: 30px;
  text-align: center;
  ${({ category }) => {
    if (category == "Cultura Aeronáutica") {
      return `
        color: #0084FF;
      `;
    }

    if (category == "Estilos de vida saludable") {
      return `
        color: #00A441;
      `;
    }

    if (category == "Prevención Covid-19") {
      return `
        color: #E63A40;
      `;
    }
    if (category == "American Airlines") {
      return `
        color: #00A2BA;
      `;
    }
  }}
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  color: #ea6803;
  width: 60%;
  font-weight: 600;
  font-size: 20px;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 30px;
  @media (max-width: 600px) {
    width: 80%;
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & img {
    width: 25%;
  }
  @media (max-width: 600px) {
    & img {
      width: 40%;
      margin-bottom: 25px;
    }
  }
`;

export const Counter = styled.h1`
  display: flex;
  margin: 0;
  & h1 {
    margin: 0;
  }
`;

export const Question = styled.p`
  text-align: center;
`;

export const Img = styled.div`
  display: flex;
  justify-content: center;
  background:white;
  position:fixed;
  bottom:0;
  width:100%;
  padding:10px;
  & img {
    width: 100%;
    max-height:40px;
    object-fit:contain
    
  }

`;

export const AnswersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  justify-content: center;
  & button {
    color: white;
    margin: 10px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    min-width: 100px;
    background-color: #ea6803;
  }
  & button:hover {
    background-color: white;
    color: #ea6803;
    cursor: pointer;
  }
  & button:focus {
    outline: none;
  }
`;

export const BackButton = styled.button`
  padding: 5px 15px 5px 15px;
  background-color: #ea6803;
  color: white;
  border-color: white;
  font-size: 20px;
  cursor: pointer;
  border: solid 1px;
  border-radius: 50px;
  margin-top: 20px;
  text-decoration: none;
  &:hover {
    background-color: white;
    color: #ea6803;
  }
`;

export const GlobalStyle = createGlobalStyle`
    ${({ category }) => {
      if (category == "One") {
        return `
          body{
            background: url(${one}) no-repeat center center;
            background-size: cover;
            min-height: 100vh;
          }
        `;
      }

      if (category == "Two") {
        return `
          body{
            background: url(${two}) no-repeat center center;
            background-size: cover;
            min-height: 100vh;
          }
        `;
      }
    }}
`;
