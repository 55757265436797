import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// components
import { AuthPage } from "./pages/AuthPage";
import { MainPage } from "./pages/MainPage";
import { GamePage } from "./pages/GamePage";
import { RankingPage } from "./pages/RankingPage";
import { ProtectedRoute } from "./guards/ProtectedRoute";
import { ProtectedRouteAttemps } from "./guards/ProtectedRouteAttemps";
import { Footer } from "./components/Footer";
//styles
import { GlobalStyles } from "./styles/GlobalStyles";
// state
import { StateProvider } from "./state/Context";
import { reducer } from "./state/Reducer";

function App() {
  const initialState = {
    userState: {
      isLogged: false,
      name: "",
      isAdmin: "",
      score: "",
      attemps: "",
    },
  };

  const session = JSON.parse(localStorage.getItem("userData"));
  const sessionScore = JSON.parse(localStorage.getItem("userScore"));
  const sessionAttemps = JSON.parse(localStorage.getItem("userAttemps"));

  if (session) {
    initialState.userState.isLogged = true;
    initialState.userState.name = session.name;
    initialState.userState.isAdmin = session.isAdmin;
    initialState.userState.score = sessionScore;
    initialState.userState.attemps = sessionAttemps;
  }

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <Router>
        <GlobalStyles />
        <Switch>
          <Route exact path="/" component={AuthPage} />
          <ProtectedRoute exact path="/main" component={MainPage} />
          <ProtectedRouteAttemps exact path="/game" component={GamePage} />
          <ProtectedRoute exact path="/ranking" component={RankingPage} />
        </Switch>
      </Router>
    </StateProvider>
  );
}

export default App;
