import React, { Fragment, useEffect, useState } from "react";
// npm
import ClipLoader from "react-spinners/ClipLoader";
// rest
import { getRanking } from "../../services/RestService";
// styles
import {
    TableContainer,
    Table,
    TableBottomContainer,
    PositionTd,
    NameTd,
    ScoreTd,
  } from "./styles";
  // images
  import AmericanLogo from "../../images/logos/Recurso-10.png";

export const RankingTable = () => {
  const [ranking, setRanking] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const showRanking = async () => {
    try {
      setIsLoading(true);
      const rankingData = await getRanking();
      setIsLoading(false);
      setRanking(rankingData.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    showRanking();
  }, []);

  return (
    <Fragment>
        {isLoading ? (
          <ClipLoader css="margin-left: 10px;" size={30} color={"white"} />
        ) : (
          <TableContainer>
            <Table>
              <tbody>
                {ranking.map((user, index) => {
                  if (user.isAdmin !== 1) {
                    return (
                      <tr key={index}>
                        <PositionTd>{index + 1}.</PositionTd>
                        <NameTd>
                          {user.name.charAt(0).toUpperCase() +
                            user.name.slice(1)}
                        </NameTd>
                        <ScoreTd>{user.score}</ScoreTd>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </Table>
            <TableBottomContainer>
              <img src={AmericanLogo} />
            </TableBottomContainer>
          </TableContainer>
        )}
    </Fragment>
  );
};
