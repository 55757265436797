import React, { useState } from "react";
// npm
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import swal from "sweetalert";
// styles
import {
  Container,
  Form,
  Input,
  Span,
  ButtonContainer,
  Button,
} from "./styles";
// rest
import { LoginUser } from "../../services/RestService";
// state
import { useStateValue } from "../../state/Context";

export const LoginCard = ({ setIsRegister }) => {
  let history = useHistory();
  const [{ userState }, dispatch] = useStateValue();
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await LoginUser(data);
      console.log(response);
      if (response.status === 200) {
        const data = {
          token: response.data.access_token,
          name: response.data.user.name,
          isAdmin: response.data.user.isAdmin,
          score: response.data.user.score,
          attemps: response.data.user.attemps,
        };
        dispatch({
          type: "LOGIN",
          newUserState: {
            isLogged: true,
            name: data.name,
            isAdmin: data.isAdmin,
            score: data.score,
            attemps: data.attemps,
          },
        });
        localStorage.setItem("userData", JSON.stringify(data));
        localStorage.setItem("userScore", JSON.stringify(data.score));
        localStorage.setItem("userAttemps", JSON.stringify(data.attemps));
        history.push("/main");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.response);
      if (!error.response) {
        return swal("Error", "Error de conexión.", "error");
      }
      return swal("Error", error.response.data.message, "error");
    }
  };

  return (
    <Container>
      <h1>Iniciar Sesión</h1>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <table>
          <tbody>
            <tr>
              <th>Correo:</th>
              <td>
                <Input
                  type="email"
                  error={errors.email}
                  name="email"
                  ref={register({ required: true })}
                />
                <Span>* Debes usar tu correo corporativo</Span>
              </td>
            </tr>
            <tr>
              <th>Clave:</th>
              <td>
                <Input
                  type="password"
                  error={errors.password}
                  name="password"
                  ref={register({ required: true })}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <ButtonContainer>
          <Button
            type="button"
            isActive={true}
            onClick={() => setIsRegister(true)}
          >
            Registrarse
          </Button>
          <Button type="submit">
            Ingresar
            {isLoading && (
              <ClipLoader
                css="margin-left: 10px;"
                size={20}
                color={"#e1751b"}
              />
            )}
          </Button>
        </ButtonContainer>
      </Form>
    </Container>
  );
};
