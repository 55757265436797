import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #DF751B;
  color: white;
  width: 90%;
  padding: 0 5% 0 5%;
  & h1 {
    text-align: center;
    font-weight: normal;
    margin-bottom: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  & th {
    text-align: left;
    font-size: 30px;
    width: 20%;
    font-weight: normal;
  }
  & th,
  td {
    padding: 10px 0 10px 0;
  }
  @media (max-width: 600px) {
    & td,
    th {
      padding: 20px 0 10px 0;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  height: 25px;
  border-radius: 8px;
  ${({ error }) =>
    error &&
    `
      background-color: #f7bbbb;
  `}
`;

export const Span = styled.span`
  font-size: 12px;
  margin-left: 10px;
  position: absolute;
  margin-top: 5px;
`;

export const Anchor = styled.a`
  color: white;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Button = styled.button`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 5% 2% 5% 2%;
  padding: 8px;
  color: #e1751b;
  background-color: white;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  ${({ isActive }) =>
    isActive &&
    `
        background-color: transparent;
        border: solid 1px white;
        color: white;
      `}
`;
