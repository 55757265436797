import React, { useState, Fragment } from "react";
// components
import { LoginCard } from "../../components/AuthCards/LoginCard";
import { RegisterCard } from "../../components/AuthCards/RegisterCard";
// styles
import {
  Container,
  LeftImage,
  RightForm,
  Logos,
  LogosBottom,
  PositivaLogo,
} from "./styles";
// images
import bottomLogo from "../../images/logos/Recurso-4.png";
import topLogo from "../../images/logos/Recurso-5.png";

export const AuthPage = () => {
  const [isRegister, setIsRegister] = useState(true);
  return (
    <Fragment>
      <Container>
        <LeftImage>
          <p>
          Te damos la bienvenida a nuestra sección <b>Trivias Positiva</b>, aquí tendrás la oportunidad de competir con tus compañeros y medir tus conocimientos generales.   
          </p>
        </LeftImage>
        <RightForm>
          <Logos>
            <img src={topLogo} alt="Logo positiva medellín"/>
          </Logos>
          {isRegister ? (
            <RegisterCard setIsRegister={setIsRegister} />
          ) : (
            <LoginCard setIsRegister={setIsRegister} />
          )}
          <LogosBottom>
            <PositivaLogo src={bottomLogo} />
          </LogosBottom>
        </RightForm>
      </Container>
    </Fragment>
  );
};
