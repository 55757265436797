import React, { useState } from "react";
// npm
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";
import swal from "sweetalert";
// styles
import {
  Container,
  Form,
  Input,
  Span,
  Anchor,
  ButtonContainer,
  Button,
} from "./styles";
// rest
import { RegisterUser } from "../../services/RestService";

export const RegisterCard = ({ setIsRegister }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    try {
      const response = await RegisterUser(data);
      console.log(response);
      if (response.status === 201) {
        setIsLoading(false);
        e.target.reset();
        return swal("Bien!", response.data.message, "success");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error.response);
      if (!error.response) {
        return swal("Error", "Error de conexión.", "error");
      }
      return swal("Error", error.response.data.message, "error");
    }
  };

  return (
    <Container>
      <h1>Lo primero es registrarte</h1>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <table>
          <tbody>
            <tr>
              <th>Nombre:</th>
              <td>
                <Input
                  error={errors.name}
                  name="name"
                  ref={register({ required: true })}
                />
              </td>
            </tr>
            <tr>
              <th>Correo:</th>
              <td>
                <Input
                  type="email"
                  error={errors.email}
                  name="email"
                  ref={register({ required: true })}
                />
                <Span>* Debes usar tu correo corporativo</Span>
              </td>
            </tr>
            <tr>
              <th>Clave:</th>
              <td>
                <Input
                  type="password"
                  error={errors.password}
                  name="password"
                  ref={register({
                    required: true,
                    minLength: {
                      value: 8,
                    },
                  })}
                />
                <Span>
                  * La contraseña debe tener por lo menos 8 carácteres.
                </Span>
              </td>
            </tr>
            <tr>
              <th></th>
              <td>
                <Anchor href="/">
                  <input
                    name="policy"
                    type="checkbox"
                    ref={register({ required: true })}
                  />
                  He leído y acepto los términos de uso de datos.
                </Anchor>
              </td>
            </tr>
          </tbody>
        </table>
        {errors.policy && (
          <span>Por favor acepte los términos de uso de datos.</span>
        )}
        <ButtonContainer>
          <Button type="submit">
            Registrarse
            {isLoading && (
              <ClipLoader
                css="margin-left: 10px;"
                size={20}
                color={"#e1751b"}
              />
            )}
          </Button>
          <Button
            type="button"
            isActive={true}
            onClick={() => setIsRegister(false)}
          >
            Ingresar
          </Button>
        </ButtonContainer>
      </Form>
    </Container>
  );
};
